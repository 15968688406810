
import axiosInstance from './axios';
import moment from 'moment';

const alphaSort = (a: any, b: any) => {
  if (a.itemName < b.itemName) { return -1; }
  if (a.itemName > b.itemName) { return 1; }
  return 0;
};

export interface MenuItemsResponse {
  groups: MenuGroup[];
}

export interface MenuGroup {
  groupName: string;
  groupDesc: string;
  effects: MenuEffect[];
}

export interface MenuDetails {
  name: string;
  menuID: number;
  description: string;
  gameID: number;
  active?: boolean;
}

export interface MenuEffect {
  itemName: string;
  duration?: string;
  code: string;
  kind: number;
  description: string;
  price: number;
  item_hidden: number;
  site_hidden: number;
  parent_id: number | null;
  inventory_hidden: number;
  inventory_id: number;
  itemID: number;
  userLimit: number;
  gameLimit: number;
  userCooldown: number;
  gameCooldown: number;
  orderMin: number;
  orderMax: number;
  scaleMode: number;
  scaleFactor: number;
}

export const getLatestVersion = async () => {
  const response = await axiosInstance.get(`https://sfo2.digitaloceanspaces.com/crowdcontrolupdater/latest.yml`);

  if ( response && response.data.includes('path') ) {
    const rawPath = response.data.match(/path: (.*)/)[1];
    const fullPath = `https://crowdcontrolupdater.sfo2.digitaloceanspaces.com/${rawPath}`;
    const version = response.data.match(/version: (\d+\.\d+\.\d+)/)[1];
    const size = `${response.data.match(/size: (\d+)/)[1].substring(0, 2)}MB`;
    const releaseDate = moment(new Date(response.data.match(/releaseDate: (.*)/)[1].replace(/'/g, ''))).format('MMM Do, YYYY');
    return { path: fullPath, version, size, releaseDate};
  } else {
    const rawPath = 'CrowdControl-Setup-5.4.5.exe';
    const fullPath = `https://crowdcontrolupdater.sfo2.digitaloceanspaces.com/${rawPath}`;
    const version = '5.4.5';
    const size = '128MB';
    const releaseDate = 'Sep 28th, 2021';
    return { path: fullPath, version, size, releaseDate};
  }
};

export const getMenus = async () => {
  const response = await axiosInstance.get(`/api/crowd-control/menus`);
  return response.data;
};

export const getSingleMenu = async (menuID: number) => {
  const response = await axiosInstance.get(`/api/crowd-control/menus/${menuID}`);
  return response.data;
};

export const saveMenu = async (menuDetails: MenuDetails, updatedMenuEffects: MenuEffect[] = []) => {
  // only send active if setting to true
  if (!menuDetails.active) {
    delete menuDetails.active;
  }

  const response = await axiosInstance.put(`/api/crowd-control/menus/${menuDetails.menuID}`,
  {
    menuDetails,
    items: updatedMenuEffects.length > 0 ? updatedMenuEffects : undefined,
  });
  return response.data ? response.data : response;
};

export const createMenu = async (menuDetails: any) => {
  // change from game to gameID param for api request
  delete Object.assign(menuDetails, {['gameID']: menuDetails.game }).game;

  const response = await axiosInstance.post(`/api/crowd-control/menus`,
    {
      menuDetails,
    });
  return response.data;
};

export const deleteMenu = async (menuID: string) => {
  const response = await axiosInstance.delete(`/api/crowd-control/menus/${menuID}`);
  return response.data;
};

export const getMenuEffects = async (menuID: number) => {
  const response = await axiosInstance.get(`/api/crowd-control/menus/${menuID}/items`);
  // parse out durations as a separate property
  (response.data as MenuItemsResponse).groups.forEach((group) => {
    group.effects.forEach((effect) => {
      const hasDuration = effect.itemName.match(/\(.*\)/);
      effect.duration = hasDuration ? hasDuration[0].slice(1, -1) as string : undefined;
      effect.itemName = effect.itemName.replace(/\(.*\)/, '').trim();
    });
  });
  return response.data ? response.data : {};
};

export const getGames = async () => {
  const response: any = await axiosInstance.get(`/api/crowd-control/games`);
  const games = response.data.games
    .sort((a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  return games;
};


export const getDefaultMenuItems = async (gameID: string) => {
  const response = await axiosInstance.get(`/api/crowd-control/menus/default/${gameID}/items`);
  return response.data ? response.data : {};
};

export const setCoinTier = async (tier: string, amount: string) => {
  const response = await axiosInstance.put(`/api/crowd-control/bonus-coins/${tier}?amount=${amount}`);
  return response.data ? response.data.body : false;
};

export const getCoinTiers = async () => {
  const response = await axiosInstance.get(`/api/crowd-control/bonus-coins`);
  return response.status === 200 ? response.data.body : false;
};


export const getChannelPointSettings = async () => {
  const response = await axiosInstance.get(`/api/crowd-control/channel-points`);
  return response.status === 200 ? response.data.body : false;
};

export const setChannelPoints = async () => {
  const response = await axiosInstance.put(`/api/crowd-control/channel-points`);
  return response.data ? response.data.body : false;
};

export const unsetChannelPoints = async () => {
  const response = await axiosInstance.delete(`/api/crowd-control/channel-points`);
  return response.data ? response.data.body : false;
};


