














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ChevronDown from '../inline-svg/ChevronDown.vue';
import TwitchLogo from '../inline-svg/TwitchLogo.vue';
import TwitterLogo from '../inline-svg/TwitterLogo.vue';
import DiscordLogo from '../inline-svg/DiscordLogo.vue';
import HelpQuestionMark from '../inline-svg/HelpQuestionMark.vue';
import UserProfilePopover from '../components/UserProfilePopover.vue';

@Component({
  components: {
    ChevronDown,
    UserProfilePopover,
    TwitchLogo,
    TwitterLogo,
    DiscordLogo,
    HelpQuestionMark,
  },
  computed: {
    ...mapState({
      user: (state: any) => state.user,
    }),
  },
})
export default class WarpNav extends Vue {

  private get location() {
    return window.location;
  }

  private handleLogout() {
    this.$router.push('/logout');
  }
}
