





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapState, mapActions } from 'vuex';
import CloseIcon from '@/inline-svg/CloseIcon.vue';
import CheckIcon from '@/inline-svg/CheckIcon.vue';
import StopWatchIcon from '@/inline-svg/StopWatch.vue';
import { saveMenu, MenuDetails, MenuEffect } from '@/services/crowd-control';

@Component({
  components: {
    CloseIcon,
    CheckIcon,
    StopWatchIcon,
  },
  computed: {
    ...mapState({
      menuDetails: (state: any): MenuDetails => state.crowdControl.currentMenu.details,
    }),
  },
  methods: {
    ...mapActions('crowdControl', ['getMenuDetails']),
  },
})
export default class MenuEfect extends Vue {
  private success = false;
  private menuDetails: any; // mapped state
  private getMenuDetails!: (menuID: string) => any; // mapped action

  @Prop() private effect!: MenuEffect;
  @Prop() private game?: any;
  @Prop() private isBasePrice?: boolean;


  // Are we currently queued up to save or actively sending a
  // save request to the server and waiting?
  private saving: boolean = false;

  // TimeoutID for the delayed callback to debounce realtime pricing input saving
  private saveTimeoutId!: number;

  // The last price that was queued up for a save
  // This is useful in the case of a user type all 9s over and over, they will max out at
  // 99999 but this prevents the UI from queuing up any more callbacks for the same price
  private lastQueuedPrice!: number | undefined;

  // This hold the initial price of an effect prior to any changes
  // we use this to track original price. If a user changes a price quickly back and forth
  // we can save a request as the original price was already saved.
  private lastInitialPrice!: number | undefined;

  // Watch for Price changes on this effect
  @Watch('effect.price')
  private onEffectPriceChange(price: number, oldPrice: number) {

    // If this is the first change since a save, lets remember this value
    if (!this.lastInitialPrice) {
      this.lastInitialPrice = oldPrice;
    }

    // If the price is out of bounds, force it back to the previous value
    if (price < 1 || price > 99999) {
      const el = this.$refs.pricingInput as Element;
      el.classList.add('shake');
      el.addEventListener('animationend', () => el.classList.remove('shake'), {once: true});
      this.effect.price = oldPrice;
      return; // We can return here as the line above will trigger the watcher again
    }

    // If we end up at the price we started at, lets cancel everything
    // and save ourselves a request
    if (this.lastInitialPrice === price) {
      clearTimeout(this.saveTimeoutId);
      this.lastInitialPrice = this.lastQueuedPrice = undefined;
      this.saving = false;
      return;
    }

    // If the price has changed since we last queued up a save, lets clear and re-queue
    if (this.lastQueuedPrice !== price) {
      this.lastQueuedPrice = price;
      this.saving = true;
      clearTimeout(this.saveTimeoutId);
      this.saveTimeoutId = setTimeout(() => {
        this.saveEffect();
        this.lastInitialPrice = this.lastQueuedPrice = undefined;
      }, 750);
    }
  }

  private async saveEffect() {
    this.saving = true;
    const results = await saveMenu(this.menuDetails, [this.effect]);
    if (results) {
      try {
        this.success = true;
        setTimeout(() => {
          this.success = false;
        }, 3500);
      } catch (e) {
        // console.log(e);
      }
    }
    this.saving = false;
  }

  private getImgUrl(gameSafeName: string, effectCode: string) {
    return `https://resources.crowdcontrol.live/images/${gameSafeName}/icons/${effectCode}.png`;
  }

  private toggleHidden() {
    this.effect.inventory_hidden = this.effect.inventory_hidden === 0 ? 1 : 0;
    this.saveEffect();
  }

  private getEnableDisableTooltipMessage(effect: any) {
    return effect.inventory_hidden === 0 ? 'Click to disable this effect' : 'Click to enable this effect';
  }
}
