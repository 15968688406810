












import Box from '@/components/Box.vue';
import { getNews } from '@/services/forum';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Box,
  },
})
export default class DashboardEvents extends Vue {
  private mainCta = {
    type: 'ad',
  };

  private async mounted() {
    this.$emit('setNav', 'home');
    this.$emit('setCta', this.mainCta);
  }
}
