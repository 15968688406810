import axiosInstance from './axios';

export const getNews = async () => {
  const response = await axiosInstance.get('https://api.warp.world/cms?id=4783');
  return response.data.data.content.replace(/<br>/g, '</p><p>');
};

export const getEvents = async () => {
  const response = await axiosInstance.get('https://api.warp.world/cms?id=4819');
  return JSON.parse(response.data.data.content.replace(/<[^>]*>/g, ''));
};

export const getSetupGuide = async (forumId?: string) => {
  const response = await axiosInstance.get(`https://api.warp.world/cms?id=${forumId || '10597'}`);

  return response && response.data && response.data.data && response.data.data.content;
};

export const getExtensionDetails = async () => {
  const response = await axiosInstance.get('https://api.warp.world/cms?id=10598');

  return response && response.data && response.data.data && response.data.data.content;
};
