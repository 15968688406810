












import Box from '@/components/Box.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Box,
  },
})
export default class DashboardNews extends Vue {

  private get news() {
    return this.$store.state.dashboard.latestNews;
  }

  private async mounted() {
    await this.$store.dispatch('dashboard/getNews', {root: true});
  }
}
