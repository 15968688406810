



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Box extends Vue {

  @Prop() private headerBorder?: boolean;

  get displayHeaderBorder() {
    if (this.headerBorder === undefined || this.headerBorder === true) {
      return true;
    } else {
      return false;
    }
  }
}
