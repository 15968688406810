





































































import { mapState, mapGetters, mapActions } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import { saveMenu, createMenu } from '@/services/crowd-control';
import Box from '@/components/Box.vue';
import ItemGroup from '@/components/ItemGroup.vue';
import CCMenuTitleDesc from './CCMenuTitleDesc.vue';
import CCMenuAdvancedConfig from './CCMenuAdvancedConfig.vue';
import Alert from '@/components/Alert.vue';

@Component({
  components: {
    Box,
    ItemGroup,
    CCMenuTitleDesc,
    CCMenuAdvancedConfig,
    Alert,
  },
  computed: {
    ...mapState({
      menuDetails: (state: any) => state.crowdControl.currentMenu.details,
      menuEffects: (state: any) => state.crowdControl.currentMenu.effects,
      newMenu: (state: any) => state.crowdControl.newMenu,
    }),
    ...mapGetters('crowdControl', [
      'getGameById',
      'flattenedEffects',
      'filteredGroups',
    ]),
  },
  methods: {
    ...mapActions('crowdControl', ['getMenuDetails', 'resetCurrentMenu']),
  },
})
export default class CCMenuDetails extends Vue {
  private isNewMenu = false;
  private preselectedGame!: string;
  private activeGroupIndex = -1;
  private configType: string = 'basic';
  private selectedGameID: string = '';
  private status: string = '';
  private alertMessage: string = '';

  private newMenu!: any; // mapped state
  private menuDetails!: any; // mapped state
  private filteredGroups!: any; // mapped state
  private getGameById!: any; // mapped state
  private getMenuDetails!: (menuID: string) => any; // mapped action
  private resetCurrentMenu!: () => any; // mapped action

  private created() {
    this.isNewMenu = this.$route.params.menuID === 'new';
    this.preselectedGame = this.$route.params.preSelect;
  }

  private async beforeRouteLeave(to: any, from: any, next: any) {
    await this.resetCurrentMenu();
    next();
  }

  private openDemoPopup(menuID: number) {
    window.open(`https://crowdcontrol.live/extensiondemo/gamemenu/${menuID}`, 'CC Effect Menu Preview ', 'width=400, height=600');
  }

  get activeGroup() {
    return this.filteredGroups.length > 1 ? this.activeGroupIndex : 0;
  }

  get menuGame() {
    if (!this.isNewMenu && Object.keys(this.menuDetails).length) {
      return this.getGameById(this.menuDetails.game);
    } else if (this.isNewMenu) {
      return this.getGameById(this.selectedGameID);
    }
    return { name: '', safe_name: '' };
  }

  get gameName() {
    if (this.isNewMenu) {
      return '';
    } else {
      return this.menuGame.name;
    }
  }

  private async handleSave() {
    if (!this.isNewMenu) {
      await this.saveMenu();
    } else {
      await this.createMenu();
    }
  }

  private resetStatus() {
    this.status = '';
  }

  private isItemType(itemTypeId: string) {
    const id = parseInt(itemTypeId, 10);
    return this.$store.state.crowdControl.itemTypes.filter((itemType: any) => itemType.id === id).length > 0;
  }

  private toggleSection(index: number) {
    if (this.activeGroupIndex !== index) {
      this.activeGroupIndex = index;
    } else {
      this.activeGroupIndex = -1;
    }
  }

  private async createMenu() {

    // check to make sure menu title and game are set
    if (!this.newMenu.game || !this.newMenu.name) {
      // menu name and game are required
      this.status = 'error';
      this.alertMessage = 'Menu must have a name and a game selected';
      setTimeout(() => {
        this.status = '';
      }, 5000);

      return;
    }

    const newMenuResponse = await createMenu(this.newMenu);

    if (newMenuResponse && !newMenuResponse.code) {
      // if there's no error code, the request was a success
      this.$router.push({ name: 'cc-menu-create-edit', params: { menuID: newMenuResponse}});
      this.$router.go(0);
    } else {
      // error
      this.status = 'error';
      this.alertMessage = `Menu creation failed. Make sure to provide all required menu details.`;
      setTimeout(() => {
        this.status = '';
      }, 5000);
    }
  }

  private async saveMenu() {

    // check to make sure menu title and game are set
    if (!this.menuDetails.game || !this.menuDetails.name) {
      // menu name and game are required
      this.status = 'error';
      this.alertMessage = 'Menu must have a name and a game selected';
      setTimeout(() => {
        this.status = '';
      }, 5000);
      return;
    }

    const result = await saveMenu(this.menuDetails);

    if (result.menuID) {
      // updated items wasn't empty and we got a success back
      this.status = 'success';

      this.alertMessage = `Menu details updated successfully.`;
      setTimeout(() => {
        this.status = '';
      }, 5000);
    } else {
      // error
      this.status = 'error';
      this.alertMessage = `Menu update failed.`;
      setTimeout(() => {
        this.status = '';
      }, 5000);
    }

    await this.refreshData(this.menuDetails.menuID);
  }

  private async refreshData(menuID: string) {
    await this.getMenuDetails(menuID);
    this.activeGroupIndex = -1;
  }

  private redirect(menuID: string) {
    this.$router.replace({ name: 'cc-menu-create-edit', params: { menuID }});
  }
}
