




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Badge extends Vue {

  @Prop() private type!: string;

}
