import {
  getGames,
  getMenus,
  getSingleMenu,
  getMenuEffects,
  getLatestVersion,
} from '@/services/crowd-control';

export default {
  namespaced: true,
  state: {
    games: [],
    menus: [],
    ccVersion: undefined,
    currentMenu: {
      id: '',
      details: {},
      effects: {},
    },
    newMenu: {
      game: -1,
      name: '',
      description: '',
      active: 0,
    },
  },
  getters: {
    getGameById: (state: any) => (gameID: string) => {
      return state.games.find((game: any ) => game.gameID === parseInt(gameID, 10));
    },
    filteredGroups: (state: any) => {
      const groups = state.currentMenu.effects.groups;
      if (groups) {
        for ( let i = 0; i < groups.length; i++) {
          const allHidden = (groups[i].effects as []).every((effect: any) => effect.site_hidden === 1);
          if (allHidden) {
            groups.splice(i, 1);
          }
        }
      }

      return groups;
    },
    unlockedGames: (state: any) => {
      return state.games
        .filter((game: any) => game.free === 1)
        .sort();
    },
    lockedGames: (state: any) => {
      return state.games
        .filter((game: any) => game.free !== 1)
        .sort();
    },
    flattenedEffects: (state: any) => {
      const effectsCopy = JSON.parse(JSON.stringify(state.currentMenu.effects));

      let flattenedEffectsArray: any[] = [];
      effectsCopy.groups.forEach((group: any) => {
        const groupEffects = group.effects.map((effect: any) => Object.assign({ group: group.groupName }, effect));
        flattenedEffectsArray = flattenedEffectsArray.concat(groupEffects);
      });
      return flattenedEffectsArray;
    },
  },
  mutations: {
    setGames(state: any, games: any) {
      state.games = games;
    },
    setCCVersion(state: any, versionInfo: any) {
      state.ccVersion = versionInfo;
    },
    setItemTypes(state: any, itemTypes: any) {
      state.itemTypes = itemTypes;
    },
    setCurrentMenuID(state: any, menuID: string) {
      state.currentMenu.id = menuID;
    },
    setCurrentMenuDetails(state: any, menuDetails: any) {
      state.currentMenu.details = menuDetails;
    },
    setCurrentMenuEffects(state: any, menuEffects: any) {
      state.currentMenu.effects = menuEffects;
    },
    resetEffectsToUpdate(state: any) {
      state.currentMenu.effectsToUpdate = [];
    },
    resetCurrentMenu(state: any) {
      state.currentMenu = {
        id: '',
        details: {},
        effects: {},
        effectsToUpdate: [],
      };
    },
    resetNewMenu(state: any) {
      state.newMenu = {
        game: -1,
        name: '',
        description: '',
        active: 0,
      };
    },
    setMenus(state: any, menus: any) {
      state.menus = menus;
    },
  },
  actions: {
    async getGames(context: any) {
      if (context.state.games.length === 0) {
        const games = await getGames();
        context.commit('setGames', games.sort());
      }
    },
    async getCCVersion(context: any) {
      if (!context.state.ccVersion) {
        const versionInfo = await getLatestVersion();
        context.commit('setCCVersion', versionInfo);
      }
    },
    async getAllMenus(context: any) {
      const menus = await getMenus();
      context.commit('setMenus', menus);
    },
    async resetCurrentMenu(context: any) {
      context.commit('resetCurrentMenu');
    },
    async resetNewMenu(context: any) {
      context.commit('resetNewMenu');
    },
    async getMenuDetails(context: any, menuID: number) {
      context.commit('setCurrentMenuID', menuID);

      try {
        const menuDetails = await getSingleMenu(menuID);
        const menuEffects = await getMenuEffects(menuID);

        if (menuEffects && Array.isArray(menuEffects.groups)) {
          const separator = ' > ';

          // Let's change the display name of all the groups with paths to their full path name
          menuEffects.groups
            .filter((g: any) => g.groupPath && g.groupPath.length)
            .forEach((group: any) => {
              const { groupPath, groupName } = group;
              group.groupName = `${groupPath.join(separator)}${separator}${groupName}`;
            });

          // General effects are always at the top, lets remove them from sorting.
          const general = menuEffects.groups.shift();
          menuEffects.groups.sort((a: any, b: any) => {
            a = a.groupName;
            b = b.groupName;
            return a && b && a.localeCompare(b);
          });
          menuEffects.groups.unshift(general);
        }

        context.commit('setCurrentMenuDetails', menuDetails);
        context.commit('setCurrentMenuEffects', menuEffects);
      } catch (e) {
        // console.log(e);
      }
    },
  },
};
