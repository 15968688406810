































import { Component, Prop, Vue} from 'vue-property-decorator';
import Box from '@/components/Box.vue';
import { getSetupGuide } from '@/services/forum';
import { mapGetters, mapState } from 'vuex';

@Component({
  components: {
    Box,
  },
   computed: {
    ...mapGetters('crowdControl', [
      'getGameById',
    ]),
    ...mapState({
      games: (state: any) => state.crowdControl.games,
    }),
  },
})
export default class CCGameGuide extends Vue {
  private game: any;
  private guideContent: any;
  private supportedGames: any;
  private getGameById: any;
  private loadStatus = 'idle';

  private games: any; // mapped state

  private get calcEffectListLimit() {
    // length of 19 to get 20 effects total
    return this.game.effects.length >= 19 ? 19 : this.game.effects.length;
  }

  private async created() {
    await this.loadGuide();
  }

  private async loadGuide() {
    try {

      this.loadStatus = 'loading';
      const game = this.games.find((x: any) => x.safe_name === this.$route.params.safeName);

      this.game = this.getGameById(game.gameID);

      if (this.game) {
        this.guideContent = await getSetupGuide(this.game.guide);
        this.loadStatus = 'success';
      } else {
        throw new Error();
      }
    } catch (e) {
      this.loadStatus = 'error';
    }
  }

}
