import axios, { AxiosInstance } from 'axios';
import store from '../store';

const axiosInstance: AxiosInstance = axios.create({
  timeout: 10000,
});

axiosInstance.interceptors.request.use((requestConfig: any) => {
  const token = store.state.token;

  // add user token if it exists and if we're calling the app api routes
  if (token && requestConfig.url.indexOf('/api/') >= 0) {
    requestConfig.headers.Authorization = `Token ${token}`;
  }

  return requestConfig;
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  return error.response;
});

export default axiosInstance;
