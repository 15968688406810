














import { Component, Prop, Vue } from 'vue-property-decorator';
import Box from '@/components/Box.vue';

@Component({
  components: {
    Box,
  },
})
export default class NoLinkedAccount extends Vue {
  private service: any = '';

  private mounted() {
    this.service = this.$store.state.services.filter((service: any) => service.identifier === this.$route.query.linkedAccountType)[0];

  }
}
