





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState, mapGetters, mapActions } from 'vuex';
import Box from '@/components/Box.vue';
import TooltipIcon from '@/inline-svg/TooltipIcon.vue';

@Component({
  components: {
    Box,
    TooltipIcon,
  },
  computed: {
    ...mapState({
      menuDetails: (state: any) => state.crowdControl.currentMenu.details,
      newMenu: (state: any) => state.crowdControl.newMenu,
      user: (state: any) => state.user,
      games: (state: any) => state.crowdControl.games,
    }),
    ...mapGetters('crowdControl', [
      'unlockedGames',
      'lockedGames',
      'getGameById',
    ]),
  },
  methods: {
    ...mapActions('crowdControl', ['resetNewMenu']),
  },
})
export default class CCMenuTitleDesc extends Vue {

  get menuGame() {
    if (this.isNewMenu) {
      return this.getGameById(this.newMenu.game) || { name: ''};
    }
    return this.getGameById(this.menuDetails.game) || { name: ''};
  }

  private showActiveCheckboxTooltip: boolean = false;
  private user!: any; // mapped state
  private unlockedGames!: any; // mapped state
  private lockedGames!: any; // mapped state
  private menuDetails!: any; // mapped state
  private newMenu!: any; // mapped state
  private getGameById!: (gameID: number) => any; // mapped getter
  private resetNewMenu!: () => void; // mapped action

  @Prop() private isNewMenu!: boolean;
  @Prop() private preselectedGame!: string;

  private created() {

    // when the data loads, if the menu is already active, disable it
    // because a menu can only be set to active, it cant be unset as the active one
    // another menu must be set to active instead
    if (!this.isNewMenu && this.menuDetails.active === 1) {
      (this.$refs.menuActiveCheckbox as HTMLInputElement).setAttribute('disabled', 'disabled');
      this.showActiveCheckboxTooltip = true;
    }

    // if there is a game preselect (coming from `find a game` page)
    // then find that option and set to be selected by default in the games list
    if (this.isNewMenu && this.preselectedGame) {
      const gameOption = document.querySelector(`option[value="${this.preselectedGame}"]`);

      if (gameOption) {
        gameOption.setAttribute('selected', 'true');
        this.newMenu.game = this.preselectedGame;

        if (!this.newMenu.name) {
          // if theres not already a menu name, then add a default one
          this.newMenu.name = `${this.user.username} - ${this.menuGame.safe_name}`;
        }
      }
    } else if (this.isNewMenu && !this.preselectedGame) {
      // clear any previously selected option
      // @ts-ignore
      const selected = document.querySelector(`option[selected]`);
      if (selected) {
        selected.removeAttribute('selected');
      }

      this.resetNewMenu();
    }

    if (this.isNewMenu && this.newMenu && this.newMenu.name === '') {
      this.newMenu.name = `${this.user.username}'s menu`;
    }
  }

  private get isEligibleUser() {
    // user is eligible to see all the games if they are affil/partner, WWPro, or if they have the unlocked_games role
    return this.user.cc.broadcaster_type !== '' || this.user.wwPro || this.user.attributes.unlocked_games === 1;
  }

  private emitGameChanged(event: any) {
    this.$emit('game-changed', event);
  }

  private handleActiveChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (!checkbox.disabled) {
      if (this.isNewMenu) {
        this.newMenu.active = checkbox.checked ? 1 : 0;
      } else {
        this.menuDetails.active = checkbox.checked ? 1 : 0;
      }
    }
  }

}
