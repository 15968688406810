import axiosInstance from './axios';

interface ProfileUpdateOptions {
  avatar?: string;
  username?: string;
  email?: string;
  canEmail?: number;
}

export const WWUserLogin = async (accessCode: any, stateString: string) => {
  const response = await axiosInstance.get(`/api/auth/login?code=${accessCode}&state=${stateString}`);
  return response.data;
};

export const getWWUser = async () => {
  const response = await axiosInstance.get(`/api/auth/profile`);
  return response.data;
};

export const updateWWUserProfile = async (profileOptions: ProfileUpdateOptions) => {
  const response = await axiosInstance.patch(`/api/auth/profile`, {
    avatar: profileOptions.avatar,
    username: profileOptions.username,
    email: profileOptions.email,
    canEmail: profileOptions.canEmail,
  });
  return response.data;
};

export const linkAccount = async (serviceID: number, providerID: number, accessCode: any, stateString: string) => {
  const response = await axiosInstance.post(`/api/auth/profile/linked-accounts`, {
    serviceID,
    providerID,
    code: accessCode,
    state: stateString,
  });
  return response.data;
};

export const unlinkAccount = async (serviceID: number, providerID: number) => {
  const response = await axiosInstance.delete(`/api/auth/profile/linked-accounts`, {
    data: {
      serviceID,
      providerID,
    },
  });
  return response.data;
};

export const getLinkedAccounts = async () => {
  const response = await axiosInstance.get(`/api/auth/profile/linked-accounts`);
  return response.data;
};

export const generateStateKey = () => {
  const keyPart1 = Math.random().toString(36).substring(2, 15);
  const keyPart2 = Math.random().toString(36).substring(2, 15);
  return `${keyPart1}${keyPart2}`;
};
