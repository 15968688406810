












import { Component, Prop, Vue } from 'vue-property-decorator';
import MainNav from '@/components/MainNav.vue';
import WarpNav from '@/components/WarpNav.vue';

@Component({
  components: {
    MainNav,
    WarpNav,
  },
})
export default class Layout extends Vue {
  private activeProductKey: string = '';
  private activeCta: any = {};

  private updateNav(event: string) {
    this.activeProductKey = event;
  }

  private updateCta(event: string) {
    this.activeCta = event;
  }
}
