




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MQMain extends Vue {
  private async mounted() {
    this.$emit('setNav', 'mq');
    this.$emit('setCta', {
      type: 'action',
      heading: `Get Started`,
      subheading: 'Create a new queue to get started queueing practically anything',
      buttonText: 'Create a new MultiQueue',
      buttonType: 'router-link',
      buttonTo: '/app/multiqueue/queues/new',
    });
  }
}

