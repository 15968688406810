



















import MenuEffect from '@/components/MenuEffect.vue';
import { MenuEffect as IMenuEffect } from '@/services/crowd-control';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    MenuEffect,
  },
})
export default class ItemGroup extends Vue {
  @Prop() private active!: boolean;
  @Prop() private activeGroupIndex!: number;
  @Prop() private group!: any;
  @Prop() private game!: any;
  @Prop() private isLast!: boolean;
  @Prop() private standAlone!: boolean;

  private toggleSection() {
    this.$emit('toggle-section', this.sectionIndex);
  }

  get sectionIndex() {
    return this.activeGroupIndex;
  }

  get isActive() {
    return this.active;
  }

  get visibleEffects() {
    const effects = ((this.group && this.group.effects) || []) as IMenuEffect[];
    return effects.filter((e: IMenuEffect) => !e.site_hidden);
  }

}
