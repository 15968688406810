

















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class Modal extends Vue {

  @Prop() private show!: boolean;
  @Prop() private primaryButtonText!: string;
  @Prop() private secondaryButtonText!: string;


  get modalShow() {
    return this.show;
  }

  get pButtonText() {
    return this.primaryButtonText;
  }

  get sButtonText() {
    return this.secondaryButtonText;
  }

  private updated() {
    if (this.show === true) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }

  private emitCloseModal() {
    this.$emit('modal-close');
  }

  private emitPrimaryClick() {
    this.$emit('primary-action');
  }

  private emitSecondaryClick() {
    this.$emit('secondary-action');
  }

}
