<template>
  <box>

    <template #header>
      <h2>Channel Points Setup</h2>
      <span>Enable and configure Channel Points settings for your Crowd Control account.</span>
    </template>
    <div>
      <h2></h2>
      <p class="leading-175 my-2 w-full md:w-3/5">When you start a Crowd Control game and you have this option enabled, your users will be able to redeem their Channel Points directly for Crowd Control coins.</p>

      <p class="leading-175 my-2 w-full md:w-3/5">
      Crowd Control will automatically create the rewards on your channel when you start a session, and remove them when you are done. All Channel Points current have a conversion rate of 100:1. Meaning for every 100 Channel Points a viewer redeems, they will get 1 Crowd Control coin. This is subject to change, and we plan to offer additional options on this in the future.</p>

      <p class="leading-175 my-2 w-full md:w-3/5">
      If you wish to use Channel Points with Crowd Control on your channel, just enable them below! </p>

      <div class="mt-8">
        <label class="flex mb-6" for="contactChoice1">
          <div class="flex-initial w-3 mr-4">
            <input type="radio" id="contactChoice1" name="charity" :value="false" @change="handleChange($event)" v-model="channelPointsEnabled">
          </div>
          <span>Disabled</span>
        </label>

        <div class="enable-charity">
          <label class="flex" for="contactChoice2">
            <div class="flex-initial w-3 mr-4">
              <input type="radio" id="contactChoice2" name="charity" :value="true" @change="handleChange($event)" v-model="channelPointsEnabled">
            </div>
            <span>Enabled</span>
          </label>
        </div>
      </div>

      <div v-if="channelPointsEnabled" class="flex flex-wrap flex-col-reverse md:flex-row md:flex-no-wrap">
        <div class="w-full md:w-4/5 lg:w-2/5 mt-8 mb-12 md:mb-0 md:mt-4 md:ml-8">
          <p class="block my-2">Channel Points are now enabled. If you have an active session, it's recommended that you <strong>stop and restart it</strong>.</p>
          <p class="block my-2 mt-4">Your viewers will be able to exchange:
            <ul class="list-reset mt-2">
              <li class="my-2">100 points for 1 coin
              <li class="my-2">1000 points for 10 coins</li>
              <li class="my-2">10000 points for 100 coins</li>
            </ul>
          </p>
        </div>
      </div>

      <Modal
        :show="showDisableConfirm"
        primaryButtonText="Turn off Channel Point Rewards"
        secondaryButtonText="Cancel"
        @modal-close="hideDisableConfirmModal()"
        @secondary-action="hideDisableConfirmModal()"
        @primary-action="unsetChannelPoints()">
        <template slot="modal-content">
          <h2>Just checking...</h2>
          <p class="mt-4 opacity-75">This will remove the Channel Points rewards for Crowd Control from your channel. If you have an active session, it's recommended to <strong>stop before doing this</strong>.</p>
        </template>
      </Modal>

      <Modal
        :show="showEnableConfirm"
        primaryButtonText="Enable Channel Point Rewards"
        secondaryButtonText="Cancel"
        @modal-close="hideEnableConfirmModal()"
        @secondary-action="hideEnableConfirmModal()"
        @primary-action="setChannelPoints()">
        <template slot="modal-content">
          <h2>Are you sure?</h2>
          <p class="mt-4 opacity-75">This will enable Channel Points rewards on your channel.</p>
        </template>
      </Modal>
    </div>
  </box>
</template>


<script>
import Box from '@/components/Box';
import Modal from '@/components/Modal';
import { getChannelPointSettings, setChannelPoints, unsetChannelPoints } from '@/services/crowd-control';
export default {
  name: 'channel-points',
  components: {
    Box,
    Modal,
  },
  data() {
    return {
      channelPointsEnabled: null,
      campaignDetails: {},
      initialChannelPoints: null,
      showEnableConfirm: false,
      showDisableConfirm: false,
      errorMessage: '',
      timeout: null,
    };
  },
  async created() {
    this.loadChannelPointsData();
  },
  methods: {
    async loadChannelPointsData() {
      const response = await getChannelPointSettings();
      this.channelPointsEnabled = response.channel_points ? true : false;
      this.initialChannelPoints = response.channel_points ? true : false;
    },
    async setChannelPoints() {
      await setChannelPoints();
      this.hideEnableConfirmModal();
    },
    async unsetChannelPoints() {
      try {
        await unsetChannelPoints();
        this.hideDisableConfirmModal();
      } catch (e) {
        this.errorMessage = e.message;
      }
      this.loadChannelPointsData();
      this.initialChannelPoints = null;
    },
    async resetChanges() {
      await this.loadChannelPointsData();
    },
    hideEnableConfirmModal() {
      document.body.classList.remove('overflow-hidden');
      this.showEnableConfirm = false;
      this.resetChanges();
    },
    hideDisableConfirmModal() {
      document.body.classList.remove('overflow-hidden');
      this.showDisableConfirm = false;
      this.resetChanges();
    },
    handleChange(e) {
      if (this.initialChannelPoints !== null && (e.target.value === 'false')) {
        // disabling, so show the disable confirm modal
        this.showDisableConfirm = true;
      } else if (this.initialChannelPoints !== null && (e.target.value === 'true')) {
        // enabling, so show the enable confirm modal
        this.showEnableConfirm = true;
      } else {
        this.resetChanges();
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.leading-175 {
  line-height: 1.75;
}
.campaign-details {
  border: 4px solid var(--color-gray-light);
}
.campaign-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  overflow: hidden;
  img {
    height: 100%;
    width: auto;
  }
}
input {
  padding: 8px 0 7px 8px;
  border: 2px solid var(--color-gray-light);
  border-radius: 4px;
  width: 100%;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid var(--color-black);
  background-size: contain;
  background-position: center center;
}
</style>

