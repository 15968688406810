










































import WWLogo from '@/inline-svg/WWLogo.vue';
import { mapState } from 'vuex';
import CloseIcon from '@/inline-svg/CloseIcon.vue';
import { Component, Vue } from 'vue-property-decorator';
import mainNavigation from '../views/navigation-master';

@Component({
  name: 'mobile-nav',
  components: {
    WWLogo,
    CloseIcon,
  },
  computed: {
    ...mapState({
      ccVersion: (state: any) => state.crowdControl.ccVersion,
    }),
  },
})
export default class MobileNav extends Vue {
  private get mainNav() {
    return mainNavigation;
  }

  get siteUrl() {
    return `${location.protocol}//${location.host}`;
  }

  private get user() {
    return this.$store.state.user;
  }

  private get location() {
    return window.location;
  }

  private closeModal() {
    this.$emit('closeModal');
  }
}
