

















































































import { mapGetters } from 'vuex';
import { Component, Prop, Vue } from 'vue-property-decorator';


@Component({
  computed: {
    ...mapGetters('crowdControl', [
      'flattenedEffects',
    ]),
  },
})
export default class CCMenuAdvancedConfig extends Vue {

  @Prop() private game?: any;

  private getImgUrl(gameSafeName: string, effectCode: string) {
    return `https://resources.crowdcontrol.live/images/${gameSafeName}/icons/${effectCode}.png`;
  }

}

