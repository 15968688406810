import { Component } from 'vue-property-decorator';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import VueCookies from 'vue-cookies';
import VueTippy, { TippyComponent } from 'vue-tippy';
import 'tippy.js/themes/light.css';

import VueVirtualScroller = require('vue-virtual-scroller');

import '@/assets/css/tailwind.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import '@/assets/css/global.scss';
import '@/assets/css/forum-content.scss';


Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(VueVirtualScroller);
Vue.component('DynamicScroller', VueVirtualScroller.DynamicScroller);
Vue.component('DynamicScrollerItem', VueVirtualScroller.DynamicScrollerItem);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
