






















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState, mapActions } from 'vuex';
import Box from '@/components/Box.vue';
import Badge from '@/components/Badge.vue';
import Modal from '@/components/Modal.vue';
import EditIcon from '@/inline-svg/EditIcon.vue';
import DeleteIcon from '@/inline-svg/DeleteIcon.vue';
import { getMenus, deleteMenu } from '@/services/crowd-control';

@Component({
  components: {
    Box,
    Badge,
    EditIcon,
    DeleteIcon,
    Modal,
  },
  computed: {
    ...mapState({
      menus: (state: any) => state.crowdControl.menus,
    }),
  },
})
export default class CCMenuList extends Vue {
  private searchQuery: string = '';
  private status: string = '';
  private menuToDelete: number | null = null;

  private get activeMenuTooltip() {
    return 'It is possible to create more than one menu for each game with different prices. The default one is the one that will be used when starting a CC session';
  }

  private getGame(gameID: string) {
    return this.$store.state.crowdControl.games.find((game: any) => game.gameID === gameID);
  }

  get filteredMenus() {
    const formattedSearchQuery = this.searchQuery.toLowerCase() === 'poke' ? 'poké' : this.searchQuery.toLowerCase();
    // @ts-ignore
    return formattedSearchQuery ? this.menus.filter((menu: any) => menu.name.toLowerCase().includes(formattedSearchQuery)) : this.menus;
  }

  private confirmMenuDelete(menuID: number) {
    this.status = 'attempting-delete';
    this.menuToDelete = menuID;
  }

  private handleCloseModal() {
    this.status = '';
    this.menuToDelete = null;
  }

  private async handleDeleteMenu(menuID: string) {
    const deleteSuccess = await deleteMenu(menuID);
    if (deleteSuccess) {
      this.$router.go(0);
    }
  }

  private openDemoPopup(menuID: number) {
    window.open(`https://crowdcontrol.live/extensiondemo/gamemenu/${menuID}`, 'Demo', 'width=400, height=600');
  }
}
