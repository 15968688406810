
























import { Component, Prop, Vue } from 'vue-property-decorator';
import ads from '../views/ads-master';

@Component
export default class CallToAction extends Vue {
  @Prop() private cta?: any;

  get ctaConfig() {
    const random = Math.round(Math.random() * (ads.length - 1));
    return this.cta.type === 'action' ? this.cta : ads[random];
  }
}
