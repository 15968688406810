






















import { Component, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import { mapState } from 'vuex';
import store from '@/store';

@Component({
  components: {
    Modal,
  },
  computed: {
    ...mapState({
      user: (state: any) => state.user,
    }),
  },
})
export default class DashboardMain extends Vue {
  private user: any;
  private showFirstLoginModal = false;

  private async created() {
    this.$emit('setNav', 'home');
    this.$emit('setCta', { type: 'ad'});

    this.showFirstLoginModal = this.user.firstLogin;
  }

  private hideFirstTimeLoginModal() {
    this.showFirstLoginModal = false;
    store.commit('clearFirstLogin');
  }

  private goToLinkedAccounts() {
    this.$router.push({ path: '/profile/linked-accounts'});
  }


}
