import {
  getNews,
  getEvents,
} from '@/services/forum';

export default {
  namespaced: true,
  state: {
    latestNews: '',
  },
  mutations: {
    setLatestNews(state: any, latestNewsText: string) {
      state.latestNews = latestNewsText;
    },
  },
  actions: {
    async getNews(context: any) {
      if (context.state.latestNews === '') {
        const news = await getNews();
        context.commit('setLatestNews', news);
      }
    },
  },
};
