




































































import { Component, Prop, Vue} from 'vue-property-decorator';
import { setCoinTier, getCoinTiers } from '@/services/crowd-control';
import Box from '@/components/Box.vue';
import Alert from '@/components/Alert.vue';
import { mapState } from 'vuex';

@Component({
  components: {
    Box,
    Alert,
  },
  computed: {
    ...mapState({
      user: (state: any) => state.user,
    }),
  },
})
export default class CCBonusCoins extends Vue {

  private tierUpperLimits: any = {
    free: 150,
    tier1: 250,
    tier2: 500,
    tier3: 1000,
  };

  private status: string = '';
  private alertMessage: string = '';

  private initialTiers: any = {};
  private bonusCoinTiers: any = {
    free: '',
    tier1: '',
    tier2: '',
    tier3: '',
  };

  private async mounted() {
    await this.initCoinTierData();
  }

  private async setCoinTier(tier: string) {
    this.resetStatus();

    const tierUpdateResult: any = await setCoinTier(tier, this.bonusCoinTiers[tier === 'free' ? `${tier}` : `tier${tier}`]);
    if (tierUpdateResult !== true) {
      this.status = 'error';
      this.alertMessage = `Error: ${tierUpdateResult}`;
      await this.initCoinTierData();
      this.startMessageTimer();
    } else {
      this.status = 'success';
      this.alertMessage = 'Coin tier amount saved successfully.';
      await this.initCoinTierData();
      this.startMessageTimer();
    }
  }

  private async initCoinTierData() {
    const tiers = await getCoinTiers();
    this.initialTiers = tiers;
    this.bonusCoinTiers.free = tiers.coins_free;
    this.bonusCoinTiers.tier1 = tiers.coins_tier1;
    this.bonusCoinTiers.tier2 = tiers.coins_tier2;
    this.bonusCoinTiers.tier3 = tiers.coins_tier3;
  }

  private startMessageTimer() {
    setTimeout(() => {
      this.resetStatus();
    }, 5000);
  }

  private resetStatus() {
    this.status = '';
  }

  private checkMinMaxValue(tier: string) {
    if (this.bonusCoinTiers[`${tier}`] > this.tierUpperLimits[`tier${tier}`]) {
      this.bonusCoinTiers[`${tier}`] = this.tierUpperLimits[`tier${tier}`];
    } else if (this.bonusCoinTiers[`tier${tier}`] < 0) {
      this.bonusCoinTiers[`${tier}`] = 0;
    }
  }

}
