import axiosInstance from './axios';

export const getCampaignDetails = async (campaignID: number) => {
  return axiosInstance.get(`/api/crowd-control/charity/campaigns/${campaignID}`);
};

export const getCharity = async () => {
  return axiosInstance.get(`/api/crowd-control/charity`);
};

export const setCharity = async (campaignID: number) => {
  return axiosInstance.post(`/api/crowd-control/charity`, { campaignID });
};

export const unsetCharity = async () => {
  return axiosInstance.post(`/api/crowd-control/charity/unset`, undefined );
};

export const getSupportedCauses = async () => {
  return axiosInstance.get(`/api/reference/charities`);
};
