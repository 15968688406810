






































































































import { Component, Prop, Vue} from 'vue-property-decorator';
import { mapState } from 'vuex';
import { getCampaignDetails, setCharity, getCharity, unsetCharity, getSupportedCauses } from '@/services/charity';
import Box from '@/components/Box.vue';
import Modal from '@/components/Modal.vue';
import Alert from '@/components/Alert.vue';

@Component({
  components: {
    Box,
    Modal,
    Alert,
  },
  computed: {
    ...mapState({
      user: (state: any) => state.user,
    }),
  },
})
export default class CCCharitySettings extends Vue {

  private user: any;
  private charityEnabled: boolean | null = null;
  private charityCampaignName = null;
  private campaignDetails: any = {};
  private initialCampaignDetails: any = {};
  private showEnableConfirm: boolean = false;
  private showDisableConfirm: boolean = false;
  private showSetCharityButton: boolean = false;
  private timeout: any = null;
  private supportedCauses = [];

  private status: string = '';
  private alertMessage: string = '';

  private resetStatus() {
    this.status = '';
  }

  private async created() {
    await this.loadCharityData();
    await this.loadSupportedCauses();
  }

  private async loadSupportedCauses() {
    const response = await getSupportedCauses();
    this.supportedCauses = response.data.body;
  }

  private async loadCharityData() {
    const response = await getCharity();

    this.charityEnabled = response.data.body.charity_campaign === null ? false : true;
    if (this.charityEnabled) {
      const campaign = await this.getCampaign(response.data.body.charity_campaign);

      this.initialCampaignDetails = JSON.parse(JSON.stringify(campaign));
      this.campaignDetails = JSON.parse(JSON.stringify(campaign));
      this.showSetCharityButton = false;
    }
  }

  private async setCharity() {
    try {
      await setCharity(this.campaignDetails.id);
      this.loadCharityData();
      this.showEnableConfirm = false;
      this.status = 'success';
      this.alertMessage = 'Charity set successfully.';
    } catch (e) {
      this.status = 'error';
      this.alertMessage = e.message;
    }
  }

  private async unsetCharity() {
    try {
      await unsetCharity();
      this.showDisableConfirm = false;
      this.status = 'success';
      this.alertMessage = 'Charity unset successfully.';
    } catch (e) {
      this.status = 'error';
      this.alertMessage = e.message;
    }
    this.loadCharityData();
    this.campaignDetails = {};
    this.initialCampaignDetails = {};
  }

  private async getCampaign(campaignID: number, event?: any) {

    // clear error message if there is one
    this.campaignDetails.error = '';

    if (event) {
      const isValid = (event.target.value && event.target.value.length >= 3 && !isNaN(event.target.value));
      if (isValid) {
        // only fetch details if the value is valid
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(async () => {

          try {
            const response = await getCampaignDetails(event.target.value);

            if (response.status !== 200) {
              this.campaignDetails = {
                id: event.target.value,
                error: `Could not find a published Tiltify campaign for a supported cause with the id ${event.target.value}`,
              };
            } else {
              this.campaignDetails = response.data.body;
              this.showSetCharityButton = true;
            }
          } catch (e) {
            this.campaignDetails = {
              id: event.target.value,
              error: `Could not find a published Tiltify campaign for a supported cause with the id ${event.target.value}`,
            };
          }

        }, 500);
      }
    } else {
      try {
        const response = await getCampaignDetails(campaignID);

        if (response.status !== 200) {
          return {
            error: `Could not find a Tiltify campaign for a supported cause with the id ${campaignID}`,
          };
        } else {
          return response.data.body;
        }
      } catch (e) {
        return {
          error: `Could not find a Tiltify campaign for a supported cause with the id ${campaignID}`,
        };
      }
    }
  }

  private resetChanges() {
    this.campaignDetails = JSON.parse(JSON.stringify(this.initialCampaignDetails));
    this.showSetCharityButton = false;
  }

  private hideEnableConfirmModal() {
    document.body.classList.remove('overflow-hidden');
    this.showEnableConfirm = false;
  }

  private hideDisableConfirmModal() {
    document.body.classList.remove('overflow-hidden');
    this.showDisableConfirm = false;
    this.charityEnabled = true;
  }

  private handleChange(e: any) {
    if (Object.keys(this.initialCampaignDetails).length && e.target.value === 'false') {
      // disabling, so show the disable confirm modal
      this.showDisableConfirm = true;
    } else {
      this.resetChanges();
    }
  }

  private getAvatarSrc(tiltifySrc: string) {
    return tiltifySrc.indexOf('https') >= 0 ? tiltifySrc : `https://tiltify.com/${tiltifySrc}`;
  }

}
