















import { Component, Prop, Vue } from 'vue-property-decorator';
import CloseIcon from '@/inline-svg/CloseIcon.vue';
import CloseCircleIcon from '@/inline-svg/CloseCircleIcon.vue';
import CheckIcon from '@/inline-svg/CheckIcon.vue';
import TooltipIcon from '@/inline-svg/TooltipIcon.vue';

@Component({
  components: {
    CheckIcon,
    CloseIcon,
    CloseCircleIcon,
    TooltipIcon,
  },
})
export default class Alerts extends Vue {

  @Prop() private type!: string;
  @Prop() private message!: string;

}
