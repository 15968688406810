const mainNavigation: any = {
  profile: {
    mobileSectionTitle: 'Profile',
    navItems: [
      {
        display: 'Your Profile',
        subText: 'Manage your Warp World account',
        to: '/profile/main',
      },
      {
        display: 'Linked Accounts',
        subText: 'Manage the accounts that are linked to Warp World products',
        to: '/profile/linked-accounts',
      },
    ],
  },
  home: {
    mobileSectionTitle: 'Dashboard',
    navItems: [
      {
        display: 'News & Updates',
        subText: 'Check back often to be the first to know when the Warp World team announces new things we’re working on',
        to: '/dashboard/news',
      },
      // {
      //   display: 'Warp World Events',
      //   subText: 'Where is Warp World? Probably coming to an event near you. If not, suggest one on our discord?',
      //   to: '/dashboard/events',
      // },
    ],
  },
  cc: {
    mobileSectionTitle: 'Crowd Control',
    navItems: [
      {
        display: 'Find your Game',
        subText: 'Check here first for how tos on setting up Crowd Control for each game we support',
        to: '/crowd-control/supported-games',
      },
      {
        display: 'Effect Menus',
        subText: 'Manage all your Effect menus, add, remove, delete, and activate them',
        to: '/crowd-control/menus',
      },
      {
        display: 'Bonus Coins',
        subText: 'Configure how many bonus coins your audience gets each CC session and monthly',
        to: '/crowd-control/bonus-coins',
      },
      {
        display: 'Channel Points',
        subText: 'Set Channel Point rewards configuration for your account',
        to: '/crowd-control/channel-points',
      },
      {
        display: 'Charity Settings',
        subText: 'Set your Crowd Control account to play for charity',
        to: '/crowd-control/charity-settings',
      },
      {
        display: 'Setup Guide',
        subText: 'Learn how to setup and configure the Crowd Control extension and desktop application',
        to: '/crowd-control/setup-guide',
      },
      {
        display: 'Extension Details',
        subText: 'Learn about how the Crowd Control Twitch extension works and how your audience will interact with it during your stream',
        to: '/crowd-control/extension-details',
      },
    ],
  },
  // mq: {
  //   mobileSectionTitle: 'Multiqueue',
  //   navItems: [
  //     {
  //       display: 'Your MultiQueues',
  //       subText: 'View all your queues and manage or start playing',
  //       to: '/multiqueue/queues',
  //     },
  //     {
  //       display: 'Bot Commands & Responses',
  //       subText: 'Configure your specific WarpBot command names and responses',
  //       to: '/multiqueue/bot-commands',
  //     },
  //   ],
  // },
  developers: {
    mobileSectionTitle: 'Developers',
    navItems: [
      {
        display: 'Crowd Control SDK Setup',
        to: 'https://forum.warp.world/t/how-to-setup-and-use-the-crowd-control-sdk',
        type: 'anchor',
      },
    ],
  },
  other: {
    mobileSectionTitle: 'Other Warp World Services',
    navItems: [
      {
        display: 'Multi-Queue',
        to: 'https://warp.world/v1/multi-queue',
        type: 'anchor',
      },
      {
        display: '1UpCoin',
        to: 'https://1upcoin.com',
        type: 'anchor',
      },
      {
        display: 'Turnip Exchange',
        to: 'https://turnip.exchange',
        type: 'anchor',
      },
    ],
  },
  privacyTerms: {
    mobileSectionTitle: 'Privacy & Terms',
    navItems: [
      {
        display: 'Privacy Policy',
        to: 'https://warp.world/privacy',
        type: 'anchor',
      },
      {
        display: 'Terms of Service',
        to: 'https://warp.world/terms',
        type: 'anchor',
      },
    ],
  },
};

export default mainNavigation;
