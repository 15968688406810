




import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  computed: {
    ...mapState({
      ccVersion: (state: any) => state.crowdControl.ccVersion,
    }),
  },
})
export default class CCMain extends Vue {
  private ccVersion!: any; // mapped state

  private async mounted() {

    this.$emit('setCta', {
      type: 'action',
      subheadType: 'html',
      heading: `Crowd Control Desktop App`,
      subheading: `<span>${this.ccVersion.releaseDate}, ${this.ccVersion.size}</span>`,
      buttonText: `Get Crowd Control ${this.ccVersion.version}`,
      buttonType: 'anchor',
      buttonTo: this.ccVersion.path,
    });
  }
}
