
























































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Route from 'vue-router';
import { mapState } from 'vuex';

import WWLogo from '@/inline-svg/WWLogo.vue';
import WWLogoMini from '@/inline-svg/WWLogoMini.vue';
import ChevronDown from '../inline-svg/ChevronDown.vue';
import MenuIcon from '@/inline-svg/MenuIcon.vue';
import MobileNav from './MobileNav.vue';
import CallToAction from './CallToAction.vue';
import UserProfilePopover from './UserProfilePopover.vue';
import mainNavigation from '../views/navigation-master';


@Component({
  name: 'main-nav',
  components: {
    MobileNav,
    CallToAction,
    WWLogo,
    WWLogoMini,
    MenuIcon,
    UserProfilePopover,
    ChevronDown,
  },
  computed: {
    ...mapState({
      user: (state: any) => state.user,
    }),
    ...mapState({
      ccVersion: (state: any) => state.crowdControl.ccVersion,
    }),
  },
})
export default class MainNav extends Vue {
  private mobileNavShown = false;
  private ccVersion!: any; // mapped state

  @Prop() private navItemSet?: string;
  @Prop() private cta?: object;


  get siteUrl() {
    return `${location.protocol}//${location.host}`;
  }

  get mainNav() {
    const filteredNav = JSON.parse(JSON.stringify(mainNavigation));
    delete filteredNav.profile;
    return filteredNav;
  }

  @Watch('$route')
  private onRouteChange(to: Route, from: Route) {
    this.mobileNavShown = false;
  }

  private isExternal(url: string) {
    return url.includes('http://') || url.includes('https://');
  }

}
