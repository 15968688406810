import Vue from 'vue';
import Router from 'vue-router';

import store from './store';

import { WWUserLogin, getWWUser } from '@/services/auth';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'layout',
      component: () => import('./views/Layout.vue'),
      beforeEnter: async (to: any, from: any, next: any) => {
        // init auth service data
        await store.dispatch('initAuthServices');

        // get CC app version info
        await store.dispatch('crowdControl/getCCVersion', { root: true});

        // get query params
        const {code, state, auth } = to.query;

        if (auth && auth === 'ww') {
          return next('/dashboard');
        } else if (auth && auth !== 'ww') {
          return next({ path: '/profile/linked-accounts', query: { linkType: auth, linkCode: code, linkState: state}});
        } else {
          return next();
        }

      },
      redirect: '/dashboard',
      children: [
        {
          path: 'profile',
          component: () => import('./views/profile/ProfileLayout.vue'),
          children: [
            {
              path: '',
              redirect: 'main',
            },
            {
              path: 'main',
              name: 'profile-main',
              component: () => import('./views/profile/ProfileMain.vue'),
            },
            {
              path: 'linked-accounts',
              name: 'profile-linked-accounts',
              component: () => import('./views/profile/LinkedAccounts.vue'),
            },
          ],
        },
        {
          path: 'dashboard',
          component: () => import('./views/dashboard/DashboardMain.vue'),
          children: [
            {
              path: '',
              redirect: 'news',
            },
            {
              path: 'news',
              name: 'dashboard-news',
              component: () => import('./views/dashboard/DashboardNews.vue'),
            },
            {
              path: 'events',
              name: 'dashboard-events',
              component: () => import('./views/dashboard/DashboardEvents.vue'),
            },
          ],
        },
        {
          path: 'crowd-control',
          component: () => import('./views/products/crowd-control/CCMain.vue'),
          async beforeEnter(to: any, from: any, next: any) {
            // get all games
            await store.dispatch('crowdControl/getGames', { root: true});
            // get all user menus
            await store.dispatch('crowdControl/getAllMenus', { root: true});
            return next();
          },
          children: [
            {
              path: '',
              redirect: 'supported-games',
            },
            {
              path: 'supported-games',
              name: 'supported-games',
              component: () => import('./views/products/crowd-control/CCSupportedGames.vue'),
            },
            {
              path: 'menus',
              name: 'cc-menu-list',
              component: () => import('./views/products/crowd-control/menus/CCMenuList.vue'),
              meta: {
                linkedAccountPrevent: 'cc',
              },
            },
            {
              path: 'menus/:menuID/edit',
              name: 'cc-menu-create-edit',
              component: () => import('./views/products/crowd-control/menus/CCMenuDetails.vue'),
              async beforeEnter(to: any, from: any, next: any) {
                if (to.params.menuID !== 'new') {
                  await store.dispatch('crowdControl/getMenuDetails', to.params.menuID, { root: true});
                }
                return next();
              },
              meta: {
                linkedAccountPrevent: 'cc',
              },
            },
            {
              path: 'bonus-coins',
              name: 'bonus-coins',
              component: () => import('./views/products/crowd-control/CCBonusCoins.vue'),
              meta: {
                linkedAccountPrevent: 'cc',
              },
            },
            {
              path: 'charity-settings',
              name: 'charity-settings',
              component: () => import('./views/products/crowd-control/CCCharitySettings.vue'),
              meta: {
                linkedAccountPrevent: 'cc',
              },
            },
            {
              path: 'channel-points',
              name: 'channel-points',
              component: () => import('./views/products/crowd-control/CCChannelPoints.vue'),
              meta: {
                linkedAccountPrevent: 'cc',
              },
            },
            {
              path: 'extension-details',
              name: 'extension-details',
              component: () => import('./views/products/crowd-control/CCExtensionDetails.vue'),
            },
            {
              path: 'setup-guide',
              name: 'setup-guide',
              component: () => import('./views/products/crowd-control/CCSetupGuide.vue'),
            },
            {
              path: 'guides/:safeName',
              name: 'setup-guides',
              component: () => import('./views/products/crowd-control/CCGameGuide.vue'),
            },
            {
              path: 'menu-exists/:gameID',
              name: 'menu-exists',
              beforeEnter: (to, from, next) => {
                // check menus in the store for one that matches `gameID` on the route.
                // if there is one, forward to that menu details page
                // otherwise, forward to the menu creation page with that game selected
                const { gameID } = to.params;
                const menu = (store.state as any).crowdControl.menus.find((menuRecord: any) => menuRecord.game === gameID);

                if (menu) {
                  return next({ name: 'cc-menu-create-edit', params: { menuID: menu.menuID }});
                } else {
                  // user doesnt have a menu for the gameID
                  // so redirect to create-menu with that game selected
                  return next({ name: 'cc-menu-create-edit', params: { menuID: 'new', preSelect: gameID }});
                }
              },
            },
          ],
        },
        {
          path: 'multiqueue',
          component: () => import('./views/products/multi-queue/MQMain.vue'),
          children: [
            {
              path: '',
              redirect: 'queues',
            },
            {
              path: 'queues',
              name: 'mq-queue-list',
            },
            {
              path: 'queues/:queueId',
              name: 'mq-single-queue',
            },
            {
              path: 'bot-commands',
              name: 'bot-commands',
            },
          ],
        },
        {
          path: 'multi-queue',
          redirect: '/multiqueue',
        },
        {
          path: '/no-linked-account',
          name: 'no-linked-account',
          component: () => import('./views/NoLinkedAccount.vue'),
        },
      ],
    },
    {
      path: '/logout',
      beforeEnter: (to: any, from: any, next: any) => {
        store.commit('clearUser');
        store.commit('clearToken');
        localStorage.removeItem('ww-token');
        Vue.$cookies.remove('ww-user');
        location.href = '/';
      },
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

const getOauthRedirect = () => {
  const loginURL = process.env.VUE_APP_LOGIN_URL;
  const isStaging = location.host.includes('staging');

  const redirectHost = `${loginURL ? loginURL : `https://${isStaging ? 'staging-' : ''}auth.warp.world`}`;
  const redirectUrl = `redirectUrl=${location.protocol}//${location.host}/app?auth=ww`;

  return `${redirectHost}/api/auth/oauth?provider=twitch&state=${generateStateKey()}&${redirectUrl}`;
};

const generateStateKey = () => {
  const keyPart1 = Math.random().toString(36).substring(2, 15);
  const keyPart2 = Math.random().toString(36).substring(2, 15);
  localStorage.setItem('WWAuthState', `twitch-${keyPart1}${keyPart2}`);
  return `twitch-${keyPart1}${keyPart2}`;
};

router.beforeEach(async (to, from, next) => {

  // first app visit
  if (from.name === null && from.matched.length === 0 && to.name !== 'dashboard-news') {
    // we are coming into the app from a fresh page load and not from another route
    // and we are trying to visit some route that isnt the dashboard
    // so save off the to location for use after we attempt to autologin
    localStorage.setItem('navigateToAfterLogin', JSON.stringify(to));
  }

  // short circuit to login-error
  if (to.path === '/login-error') {
    return next();
  }

  const { code, auth, state } = to.query;
  const token = Vue.$cookies.get('ww-user');

  if (store.state.user && store.state.token) {
    // logged in already
    // proceed to next hook
    return next();
  } else if (token) {

    // have token, so retrieve user
    store.commit('setToken', token);

    const accountInfo = await getWWUser();

    // if status == banned redirect to the site instead of the dashboard
    if (accountInfo.status === 'banned') {
      store.commit('clearUser');
      store.commit('clearToken');
      Vue.$cookies.remove('ww-user');

      // kick back out to the main site
      location.href = `${location.protocol}//${location.host}`;
    } else {
      // else continue on to the route
      store.commit('setUser', accountInfo);
      return next();
    }

  } else {
    const localState = localStorage.getItem('WWAuthState');
    // no user, no token
    // check for code
    if (code && (auth && auth === 'ww') && state === localState) {

      // have code and ww auth, and state checks out
      // login and go to next hook
      try {
        const loginResponse = await WWUserLogin(code, state);

        // if status == banned redirect to main site instead of the dashboard
        if (loginResponse.account.status === 'banned') {
          // kick back out to the main site
          location.href = `${location.protocol}//${location.host}`;
        } else {

          // set infos
          Vue.$cookies.set('ww-user', loginResponse.token, '2d');
          store.commit('setToken', loginResponse.token);

          // set token first so the profile call can use it
          const accountInfo = await getWWUser();

          store.commit('setUser', {
            firstLogin: loginResponse.firstLogin,
            ...accountInfo,
          });

          // check for localStorage navigateToAfterLogin
          // if present route there
          const optionalRouteForward = localStorage.getItem('navigateToAfterLogin');
          if (optionalRouteForward) {
            const toRoute = JSON.parse(optionalRouteForward);
            return next(toRoute);
          }

          // else continue on to the dashboard
          return next();
        }
      } catch (e) {
        // kick back out to the main site
        location.href = `${location.protocol}//${location.host}`;
      }
    } else {
      // attempt to auto login by redirecting to the auth endpoint
      location.href = getOauthRedirect();
    }
  }
});

// Linked account prevention
router.beforeEach((to: any, from: any, next: any) => {
  const linkedAccountType = to.meta.linkedAccountPrevent;

  if (linkedAccountType) {
    if (store.state.user && !store.state.user[linkedAccountType]) {
      return next({path: '/no-linked-account', query: { linkedAccountType }});
    }
  }

  return next();
});

router.afterEach((to) => {
  const navigateTo = localStorage.getItem('navigateToAfterLogin');
  if ( navigateTo ) {
    const parsedRoute = JSON.parse(navigateTo);

    if (parsedRoute.name === to.name) {
      localStorage.removeItem('navigateToAfterLogin');
    }
  }
});

export default router;
