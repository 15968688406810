const ads: any = [
  {
    heading: 'Checkout the Warp World Patreon',
    buttonText: 'Warp World Patreon',
    buttonTo: 'https://patreon.warp.world',
    buttonType: 'link',
  },
  // {
  //   heading: 'Check out the Warp World podcasts',
  //   buttonText: 'Podcasts',
  //   buttonTo: 'https://warp.world/content',
  //   buttonType: 'link',
  // },
  // {
  //   heading: `We've got merch!`,
  //   buttonText: 'Get Merch',
  //   buttonTo: '/../merch',
  //   buttonType: 'anchor',
  // },
];

export default ads;
