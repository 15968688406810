














































import Box from '@/components/Box.vue';
import store from '@/store';
import Modal from '@/components/Modal.vue';
import Alert from '@/components/Alert.vue';
import CheckCircleIcon from '@/inline-svg/CheckCircleIcon.vue';
import CCLogo from '@/inline-svg/CCLogo.vue';
import { Component, Vue } from 'vue-property-decorator';
import { linkAccount, unlinkAccount, generateStateKey, getLinkedAccounts, getWWUser } from '../../services/auth';

@Component({
  components: {
    Box,
    CheckCircleIcon,
    CCLogo,
    Modal,
    Alert,
  },
})
export default class LinkedAccounts extends Vue {
  private status = '';
  private alertMessage = '';
  private linkType: any = null;
  private linkCode: any = null;
  private linkState: any = null;
  private linkedAccounts: any = [];
  private showScopesModal = false;
  private modalService: string = '';
  private modalProvider: string = '';
  private modalScopes: string = '';
  private modalScopeRationale: string = '';

  private async created() {
    // we're coming back from twitch auth with query params
    if (this.$route.query.linkType) {
      const { linkType, linkCode, linkState} = this.$route.query;

      this.linkType = linkType;

      const service = linkType === 'cc' ? this.ccService : this.mqService;
      const provider = linkType === 'cc' ? this.twitchAuthCC : this.twitchAuthMQ;

      this.linkCode = linkCode;
      this.linkState = linkState;
      // attempt login/link
      // retrieve linked accounts
      try {
        await linkAccount(service.id, provider.id, this.linkCode, this.linkState);

        // fetch the profile again so the linked account sections get added to the store
        store.commit('setUser', await getWWUser());
        this.status = 'success';
        this.alertMessage = 'Account linked successfully';
      } catch (e) {
        this.status = 'error';
        this.alertMessage = 'Linked accounts could not be retrieved';
      }

      // replace the route to remove query params from address bar after stored in memory
      this.$router.replace({ path: '/profile/linked-accounts'});
    }

    // retrieve linked accounts
    try {
      this.linkedAccounts = await getLinkedAccounts();
    } catch (e) {
      this.status = 'error';
      this.alertMessage = 'Linked accounts could not be retrieved';
    }

  }

  get ccService() {
    return this.$store.getters.ccService;
  }

  get mqService() {
    return this.$store.getters.mqService;
  }

  get twitchAuthCC() {
    return this.$store.getters.twitchAuthCC;
  }

  get twitchAuthMQ() {
    return this.$store.getters.twitchAuthMQ;
  }

  private handleShowScopesModal(serviceIdent: string, providerIdent: string) {
    const service = this.$store.getters[`${serviceIdent}Service`];

    this.modalService = service.displayName;
    const { displayName, scopes, scopeRationale } = service.providers.filter((provider: any) => provider.identifier === providerIdent)[0];
    this.modalProvider = displayName;
    this.modalScopes = scopes;
    this.modalScopeRationale = scopeRationale;

    this.showScopesModal = true;
  }

  private handleCloseModal() {
    this.showScopesModal = false;
  }

  private linkAccountUrl(productType: string) {

    // generate and store a random state for validity checking when the redirect comes back from twitch
    const randoState = generateStateKey();
    localStorage.setItem(`${productType}AuthState`, randoState);

    // get provider for productType
    const provider = productType === 'cc' ? this.twitchAuthCC : this.twitchAuthMQ;

    const twitchRedirectUrl = 'https://id.twitch.tv/oauth2/authorize'
    + '?client_id=' + provider.clientID
    + '&redirect_uri=' + encodeURIComponent(`${location.protocol}//${location.host}/app?auth=${productType}`)
    + '&response_type=code'
    + '&scope=' + encodeURIComponent(provider.scopes)
    + '&state=' + randoState; // random state param

    location.href = twitchRedirectUrl;
  }

  private async unlinkAcct(serviceIdent: string) {

    const service = serviceIdent === 'cc' ? this.ccService : this.mqService;
    const provider = serviceIdent === 'cc' ? this.twitchAuthCC : this.twitchAuthMQ;

    try {
      // attempt unlink
      await unlinkAccount(service.id, provider.id );

      // fetch the profile again so the linked account sections get added to the store
      store.commit('setUser', await getWWUser());
      this.status = 'success';
      this.alertMessage = 'Account unlinked successfully';
    } catch (e) {
      this.status = 'error';
      this.alertMessage = 'Linked accounts could not be retrieved';
    }

    // get linked accounts again so the UI is accurate
    this.linkedAccounts = await getLinkedAccounts();
  }

  private isAccountLinkedForProduct(serviceIdent: string) {
    const service = this.$store.getters[`${serviceIdent}Service`];
    const filteredLinkedAccounts = this.linkedAccounts.filter((account: any) => account.serviceID === service.id);
    return filteredLinkedAccounts.length > 0 ? true : false;
  }

  private getLinkedAcctUsername(serviceIdent: string, providerID: string) {
    const service = this.$store.getters[`${serviceIdent}Service`];
    const filteredLinkedAccounts = this.linkedAccounts.filter((account: any) => account.serviceID === service.id && account.providerID === providerID);
    return filteredLinkedAccounts[0].externalUsername;
  }

  private resetStatus() {
    this.status = '';
    this.alertMessage = '';
  }
}
