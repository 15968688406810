












































import { Component, Prop, Vue } from 'vue-property-decorator';
import store from '@/store';
import { mapState } from 'vuex';
import Box from '@/components/Box.vue';
import CheckCircleIcon from '@/inline-svg/CheckCircleIcon.vue';
import Alert from '@/components/Alert.vue';
import { updateWWUserProfile, getWWUser } from '@/services/auth';

@Component({
  components: {
    Box,
    Alert,
    CheckCircleIcon,
  },
  computed: {
    ...mapState({
      user: (state: any) => state.user,
    }),
  },
})
export default class ProfileMain extends Vue {
  private user: any;
  private status = '';
  private alertMessage = '';

  private resetStatus() {
    this.status = '';
    this.alertMessage = '';
  }

  private getCanEmailStatus(emailType: string) {
    let checkboxChecked = false;
    switch (emailType) {
      case 'newsletter':
        checkboxChecked = this.user.canEmail === 2 || this.user.canEmail === 3;
        break;
      case 'summary':
        checkboxChecked = this.user.canEmail === 1 || this.user.canEmail === 3;
        break;
    }
    return checkboxChecked;
  }

  private async handleSaveEmailSettings() {
    const summary = this.$refs.summary as HTMLInputElement;
    const newsletter = this.$refs.newsletter as HTMLInputElement;

    let canEmail;
    if (summary.checked && newsletter.checked) {
      canEmail = 3;
    } else if (!summary.checked && !newsletter.checked) {
      canEmail = 0;
    } else {
      if (summary.checked) {
        canEmail = 1;
      } else {
        canEmail = 2;
      }
    }

    try {
      const response = await updateWWUserProfile({ canEmail });
      if (response.success) {
        this.status = 'success';
        this.alertMessage = 'Email preferences updated successfully.';
        store.commit('setUser', await getWWUser());
      } else {
        throw new Error();
      }
    } catch (e) {
      this.status = 'error';
      this.alertMessage = `Unable to update email preferences. ${e.message}`;
    }
  }

}
