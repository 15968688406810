










import { Component, Vue } from 'vue-property-decorator';
import Box from '@/components/Box.vue';
import { getSetupGuide } from '@/services/forum';

@Component({
  components: {
    Box,
  },
})
export default class CCSetupGuide extends Vue {
  private ccSetupGuide = '';

  private async mounted() {
    this.ccSetupGuide = await getSetupGuide();
  }

}

