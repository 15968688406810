










import { Component, Prop, Vue } from 'vue-property-decorator';
import Box from '@/components/Box.vue';
import { getExtensionDetails } from '@/services/forum';

@Component({
  components: {
    Box,
  },
})
export default class CCExtensionDetails extends Vue {
  private extensionGuide = '';

  private async mounted() {
    this.extensionGuide = await getExtensionDetails();
  }

}
