















































import { Component, Prop, Vue} from 'vue-property-decorator';
import Box from '@/components/Box.vue';
import { mapState } from 'vuex';


@Component({
  components: {
    Box,
  },
  computed: {
    ...mapState({
      games: (state: any) => state.crowdControl.games,
    }),
  },
})
export default class CCSupportedGames extends Vue {
  private games: any;
  private searchedName = '';
  private selectedConsole = '';

  private subGames() {
    const filteredGames = this.games
      .filter((game: any) => {
        return this.gameNameCheck(game) && this.consoleCheck(game);
      });

    return filteredGames;
  }

  private systems() {
    const systemsArray = this.games.map((game: any) => game.path);
    const uniqueSystemsArray = Array.from(new Set(systemsArray));
    return uniqueSystemsArray;
  }

  private getGameArt(gameSafeName: string) {
    return `https://resources.crowdcontrol.live/images/${gameSafeName}/box.jpg`;
  }

  private handleImageNotFound(event: any) {
    event.target.src = 'https://resources.crowdcontrol.live/images/TwitchCommunityFallback/demos/x.png';
  }

  private gameNameCheck(game: any) {
    if (this.searchedName === '' || game.name.toLowerCase().includes(this.searchedName.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  }

  private consoleCheck(game: any) {
    if (this.selectedConsole === '' || game.path.toLowerCase() === this.selectedConsole.toLowerCase()) {
      return true;
  } else {
      return false;
    }
  }
}
