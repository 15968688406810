import Vue from 'vue';
import Vuex from 'vuex';
import ccStore from './views/products/crowd-control/cc-store';
import dashboardStore from './views/dashboard/dashboard-store';

Vue.use(Vuex);
Vue.config.devtools = true;

interface RootState {
  user: any | null;
  token: string | null;
  services: any | null;
}

export default new Vuex.Store<RootState>({
  state: {
    user: null,
    token: null,
    services: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    clearUser(state) {
      state.user = null;
    },
    clearToken(state) {
      state.token = null;
    },
    clearFirstLogin(state) {
      state.user.firstLogin = null;
    },
    setAuthServices(state, servicesObject) {
      state.services = servicesObject;
    },
  },
  getters: {
    ccService: (state: any) => {
      return state.services.filter((service: any) => service.identifier === 'cc')[0];
    },
    mqService: (state: any) => {
      return state.services.filter((service: any) => service.identifier === 'mq')[0];
    },
    twitchAuthCC: (state: any) => {
      const CCservice = state.services.filter((service: any) => service.identifier === 'cc')[0];
      return CCservice.providers.filter((provider: any) => provider.identifier === 'twitch')[0];
    },
    twitchAuthMQ: (state: any) => {
      const MQservice = state.services.filter((service: any) => service.identifier === 'mq')[0];
      return MQservice.providers.filter((provider: any) => provider.identifier === 'twitch')[0];
    },
  },
  actions: {
    async initAuthServices({commit}) {
      if (!this.state.services) {
        const response = await fetch(`/api/auth/products`);
        if (response) {
          commit('setAuthServices', await response.json());
        } else {
          throw new Error('Unable to obtain services details from Auth Service.');
        }
      }
      return;
    },
  },
  modules: {
    dashboard: dashboardStore,
    crowdControl: ccStore,
  },
});
