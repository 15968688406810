




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ProfileLayout extends Vue {

  private async mounted() {
    this.$emit('setNav', 'profile');
    this.$emit('setCta', { type: 'ad'});
  }
}
